html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

#root {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.visx-tooltip {
  position: absolute;
  z-index: 1000;
}
.visx-tooltip.visx-tooltip-glyph {
  width: 1px;
  height: 1px;
}

/** rotate x axis tick labels on small devices */
@media (max-width: 600px) {
  .time-series__chart .visx-axis:first-child .visx-axis-tick text {
    transform: translate(0, 10px) rotate(-45deg);
  }
}
